import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import icon from '../../media/icon.svg';
import './faqstyle.css';

export default function FaqForm({ setSearchTerm }) {
  const handleSubmit = (e) => {
    e.preventDefaults();
  };
  const searchCat = (e) => {
    setSearchTerm(e.target.value);
  };

  const styles = {
    background: `url(${icon})`,
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    backgroundPositionx: '15px',
    backgroundSize: 'auto',
    padding: '25px',
    backgroundColor: 'white',
    marginTop: '-35px',
  };
  return (
    <div className="p-3 bg-white">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <div>
              <Form.Control
                as="input"
                placeholder="search"
                name="search"
                id="search"
                onChange={searchCat}
                style={styles}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
