import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import TitledText from '../TitledText';
import Filter from './filter';
import getDistanceBetweenPoints from 'get-distance-between-points';
import TopicServices from '../HealthTopics/TopicServices';

function HealthServicesIndex() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const [viewType, setViewType] = useState([]);
  const [sort, setSort] = useState('');
  const [serviceFilters, setServiceFilters] = useState([]);
  const [ratingFilters, setRatingFilters] = useState([]);
  const [propFilters, setPropFilters] = useState([]);
  const allProviders = t('providers', { returnObjects: true });
  const [providers, setProviders] = useState(allProviders);

  /** GELOCATION **/
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(false);
  /** E:GELOCATION **/

  const serviceFilterValues = [
    'PHYSICAL_HEALTH', 
    'MENTAL_HEALTH',
    'SOCIAL_HEALTH',
    'HEALTHY_LIFESTYLE',
    'COVID_19',
    'HEALTHCARE_SYSTEM',
    'ENVIRONMENTAL_HEALTH'
 ];

 const ratingFilterValues = [
    'ABOVE_4_STARS', 
    'BETWEEN_3_4_STARS',
    'BETWEEN_1_3_STARS'
 ];

 const propFilterValues = [
    '24_HOURS', 
    'SEND_AMBULANCE',
    'CALL_CENTER',
    'PARK_AREA',
    'SOCIAL_CARE',
    'PROTECTION',
    'PSYCHO_SOCIAL_SUPPORT',
    'INDIVIDUAL_COUNSELLING',
    'REFERRAL_SERVICES',
    'LIVELIHOODS',
    'SOCIO_ECONOMIC_SUPPORT',
    'INTERCULTURAL_ACTIVITIES',
    'LIFE_SKILLS_TRAINING',
    'ARABIC_LANGUAGE',
    'CULTURAL_EVENTS',
    'SOCIAL_ACTIVITIES',
    'PEER_EDUCATION',
    'PSYCHO_EDUCATION',
    'AWARENESS_GROUPS',
    'COUPLES',
    'PSYCHOLOGICAL_EVALUATION',
    'FAMILY_THERAPY',
    'INDIVIDUAL'
 ];

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus(false);
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(true);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      }, () => {
        setStatus(false);
      });
    }
  }

  useEffect(()=> {
    getLocation()
  }, []);
  
 /* eslint-disable */
  useEffect(() => {

    function getDistance(provider){
        if(!lat || !lng || !status) return 0;
        const distance = getDistanceBetweenPoints.getDistanceBetweenPoints(
          lat,lng,provider.lat,provider.lng
        ) * 0.001;
        return distance;
    }

    let filtetedProvs = allProviders
        .filter(item => {
            const isKeyMatch = keyword.length ? item.name
                .toLowerCase()
                .indexOf(keyword.toLocaleLowerCase()) > - 1 : true;

            const isTypeMatch = viewType.length 
                ? viewType.map(v => v.value).includes(item.typeKey) 
                : true;

            const isServiceMatch = serviceFilters.length 
                ? serviceFilters.some(f => item.services.includes(f))
                : true; 

            const isExtraMatch = propFilters.length 
                ? propFilters.some(f => item.extras.includes(f))
                : true;     

            let isRatingMatch = false;
            if(!ratingFilters.length) {
                isRatingMatch = true;
            }
            else {
                if(ratingFilters.includes('ABOVE_4_STARS')) {
                    if(item.rating > 4) isRatingMatch = true;
                }
                if(!isRatingMatch && ratingFilters.includes('BETWEEN_3_4_STARS')) {
                    if(item.rating > 3 && item.rating < 4) isRatingMatch = true;
                }
                if(!isRatingMatch && ratingFilters.includes('BETWEEN_1_3_STARS')) {
                    if(item.rating < 3) isRatingMatch = true;
                }
            }

            return isKeyMatch && isTypeMatch && isServiceMatch && isExtraMatch && isRatingMatch;
        });

    switch(sort) {
        case 'LOW_TO_HIGH': 
            filtetedProvs.sort((a,b) => a.rating - b.rating);
        break;
        case 'HIGH_TO_LOW': 
            filtetedProvs.sort((a,b) => b.rating - a.rating);
        break;
        case 'NEAREST': 
            if(status) {
                filtetedProvs.sort((a,b) => getDistance(a) - getDistance(b));
            }
        break;
        default: break;
    }
    
    setProviders(filtetedProvs);

  }, [keyword, viewType, sort, serviceFilters, ratingFilters, propFilters, status, lat, lng])

  function changeKeyword(_keyword) {
    setKeyword(_keyword);
  }

  function changeView(values) {
    setViewType(values);
  }

  function changeSortBy(values) {
    setSort(values.length ? values[0].value : '');
  }

  function changeFilters(values) {
    parseFilterValues(values);
  }

  function parseFilterValues(values) {

    const _serviceFilters = values.filter(f => serviceFilterValues.includes(f.value)).map(f => f.value);
    setServiceFilters(_serviceFilters);

    const _ratingFilters = values.filter(f => ratingFilterValues.includes(f.value)).map(f => f.value);
    setRatingFilters(_ratingFilters);

    const _propFilters = values.filter(f => propFilterValues.includes(f.value)).map(f => f.value);
    setPropFilters(_propFilters);
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Row className="justify-content-center py-5 post-header topic-header">
          <Col xs={10} lg={5} className="my-5 text-center">
            <TitledText title={t('healthProvidersSection.title')} text={t('healthProvidersSection.description')} isMainDescription={true} titleLevel={2} />
          </Col>
        </Row>
        <Filter
          changeKeyword={changeKeyword}
          changeView={changeView}
          changeSortBy={changeSortBy}
          changeFilters={changeFilters}/>
        <Row>
          <div className='container'>
            <TopicServices providers={providers}></TopicServices>
          </div>
        </Row>
      </Route>
    </Switch>
  );
}

export default HealthServicesIndex;
