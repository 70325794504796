import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import CustomCard from './NewsCard';

function NewsMain({ posts }) {
  return (
    <>
      <Row className="d-flex justify-content-center my-5">
        <Col xs={10}>
          <Row className="my-4">
            {posts
              .filter((post) => post.category === 'Event')
              .slice(0, 2)
              .map((post, index) => (
                <Col xs={12} md={6}>
                  <CustomCard main post={post} index={index} />
                </Col>
              ))}
          </Row>
          <Row className="my-4">
            {posts
              .filter((post) => post.category === 'Article')
              .slice(1, 4)
              .map((post, index) => (
                <Col xs={12} md={4}>
                  <CustomCard main post={post} index={index} />
                </Col>
              ))}
          </Row>
          <Row className="d-flex justify-content-center my-5">
            {posts
              .filter((post) => post.category === 'Article')
              .slice(0, 2)
              .map((post, index) => (
                <Col>
                  <CustomCard post={post} index={index} />
                </Col>
              ))}
          </Row>
          <Row className="d-flex justify-content-center my-5">
            {posts
              .filter((post) => post.category === 'Article')
              .slice(2, 4)
              .map((post, index) => (
                <Col>
                  <CustomCard post={post} index={index} />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default NewsMain;
