import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Accordion from '../Accordion';
import { useTranslation } from 'react-i18next';

function TopicFAQs({ faqs }) {
  const { t } = useTranslation();
  return (
    <>
      <Row className='py-0 px-0'>
        <Col className='col-12'>
          {faqs.length ? <Accordion filteredFaq={faqs} /> : <div className='col-12 not-found'><span >{t('notFounds.faqs')} </span></div> }
        </Col>
      </Row>
    </>
  );
}

export default TopicFAQs;
