import React from 'react';
import FaqHeader from './FaqHeader';
import FaqRow from './FaqRow';
import MainFAQSCat from './MainFAQSCat';
import { useTranslation } from 'react-i18next';

function ReachFAQs() {
  const { t } = useTranslation();
  const searchTerm = '';
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    function getCat() {
      const data = t('topics.categories.topics').filter((cat) =>
        cat.name.toLowerCase().includes(`${searchTerm}`.toLowerCase())
      );

      if (data) {
        setCategories(data);
      } else {
        setCategories([]);
      }
    }
    getCat();
  }, [searchTerm, t]);
  return (
    <>
      <FaqHeader />
      <MainFAQSCat categories={categories} />
      <FaqRow />
    </>
  );
}
export default ReachFAQs;
