import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TitledText from '../TitledText';

function NewsPage({ title, description, buttonText }) {
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={11} md={10} lg={6} className="text-center">
          <TitledText title={title} text={description} titleLevel={1} />
        </Col>
      </Row>
    </>
  );
}

export default NewsPage;
