import { MultiSelect } from "react-multi-select-component";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function FilterDropdown({ label, options, change, onlyOne }) {
    
  options = (options || []).map(
    item => ({ label: item.title, value: item.key })
  );
  const [selected, setSelected] = useState([]);

  return (
    <div className='dropdown-container fixed-dropdown d-flex flex-row'>
      <div className='input-elem'>
        <MultiSelect
            hasSelectAll={false}
            disableSearch={true}
            options={options}
            value={selected}
            onChange={(event) => { 
                onlyOne && event.length 
                ? setSelected([event[Math.max(event.length - 1, 0)]]) 
                : setSelected(event); 
                change(event);
            }}
            labelledBy={label}
            overrideStrings={{ selectSomeItems: label }}
        />
      </div>
    </div>
  );
}

function Filter({ changeKeyword, changeView, changeSortBy, changeFilters }) {

  const { t } = useTranslation();
  const filterData = t('filters', {returnObjects: true});
  const viewTypeOptions = filterData.find(filters => filters.key === 'Provider Type');
  const sortBy = filterData.find(filters => filters.key === 'Sort By');

  const extras = ['Service Type', 'Service Quality', 'Extras'];
  const extraFilters = filterData.filter(f => extras.includes(f.key)).reduce((cur, acc) => [...cur, ...acc.options], []).filter(f => f.title !== 'All');

  return (
    <>
      <div className='filter-page row'>
        <div className='filter-form-wrapper mb-3'>
          <div className='container'>
            <div className='filter-form d-flex col-12 flex-md-row flex-column'>
              {/* SEARCH */}
              <div className='filter-items col-12 col-md-4 px-0'>
                <input
                  placeholder='Search in service providers...'
                  type='text'
                  className='search-box w-100'
                  onChange={(event) => { changeKeyword(event.target.value) }}
                />
              </div>
              {/* E:SEARCH */}
              {/* FILTERS */}
              <div className='filter-items col-12 col-md-8 flex-row px-0'>
                <div className='filter-item mx-md-2'>
                  <FilterDropdown
                    change={changeView}
                    label={viewTypeOptions.title}
                    options={viewTypeOptions.options}>
                  </FilterDropdown>
                </div>
                <div className='filter-item mx-md-2'>
                  <FilterDropdown
                    change={changeSortBy}
                    onlyOne={true}
                    label={sortBy.title}
                    options={sortBy.options}>
                  </FilterDropdown>
                </div>
                <div className='filter-item mx-md-2'>
                  <FilterDropdown
                    change={changeFilters}
                    label={extraFilters.title}
                    options={extraFilters}
                    >
                  </FilterDropdown>
                </div>
              </div>
              {/* E:FILTERS */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;
