//import { getAnalytics } from "firebase/analytics";
import firebase from 'firebase/compat';

const firebaseConfig = {
  apiKey: "AIzaSyB5UTwK5lzDSBc6zSN11WEJa5NNE6iQWoM",
  authDomain: "reachapp-68717.firebaseapp.com",
  databaseURL: "https://reachapp-68717.firebaseio.com",
  projectId: "reachapp-68717",
  storageBucket: "reachapp-68717.appspot.com",
  messagingSenderId: "476246175139",
  appId: "1:476246175139:web:9b0c2f50f7e42ae04ebe16",
  measurementId: "G-NSNXNJFLJJ"
};

const app = firebase.initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

const db = app.firestore()

export {db};
