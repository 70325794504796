import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import HomeCover from './HomeCover';
import HomeAbout from './HomeAbout';
import Quotes from './Quotes';
import Categories from '../Categories';
import PartnersCarousel from '../PartnersCarousel';
import NewsCarousel from '../NewsCarousel';
import '../../style/home.css';

function HomePage() {
  const { t } = useTranslation();
  const [keyword] = useState('');
  const [category, setCategory] = useState(i18next.language === "en" ? "3205" : "71316");
  const [language, setLanguage] = useState({
    article: i18next.language === "en" ? 'Article' : 'Haber',
    event: i18next.language === "en" ? 'Event' : 'Etkinlik',
  });
  const [posts, setPosts] = useState([]);
  const url =
    'https://public-api.wordpress.com/wp/v2/sites/reach4healthapp.wordpress.com/posts';
  i18next.on('languageChanged', (lng) => {
    switch (lng) {
      case 'ar':
        break;
      case 'tr':
        setCategory('71316');
        setLanguage({ article: 'Haber', event: 'Etkinlik' });
        break;
      default:
        setCategory('3205');
        setLanguage({ article: 'Article', event: 'Event' });
        break;
    }
  });
  useEffect(() => {
    const fetchBlogPosts = async () => {
      const data = await fetch(`${url}/?categories=${category}`);
      const fetchedBlogPosts = await data.json();
      setPosts(
        fetchedBlogPosts.map((el) => {
          return {
            id: el.id,
            title: el.title.rendered.replace(/&nbsp;/g, ' '),
            excerpt: el.excerpt.rendered,
            content: el.content.rendered,
            media: el.jetpack_featured_media_url,
            category: el.tags.find((tag) => tag === 3867) ? 'Article' : 'Event',
            categoryLanguage: el.tags.find((tag) => tag === 3867)
              ? language.article
              : language.event,
          };
        })
      );
    };
    fetchBlogPosts();
  }, [category, language]);
  return (
    <>
      <HomeCover
        title={t('home.title')}
        highlight={t('home.highlight')}
        description={t('home.subtitle')}
        btnText={t('home.request')}
        placeholder={t('home.form')}
      />

      <HomeAbout
        title={t('home.healthApp')}
        description={t('home.description')}
      />

      <Categories keyword={keyword} />

      <Quotes />

      <PartnersCarousel />

      {i18next.language !== 'ar' && (
        <NewsCarousel
          title={t('home.news.title')}
          posts={posts}
          button={t('home.news.button')}
        />
      )}
    </>
  );
}

export default HomePage;
