import React, { useState } from 'react';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Topic from './Topic';
import TitleBar from '../TitleBar';
import Categories from '../Categories';

function Topics() {
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');

  return (
    <Switch>
      <Route exact path={path}>
        <TitleBar
          title={t('topics.title')}
          description={t('topics.description')}
          changeKeyword={(event) => setKeyword(event)}
        />
        <Categories keyword={keyword} />
        <Link to={`${url}/topic-1`}></Link>
      </Route>

      <Route path={`${path}/:topicId`}>
        <Topic />
      </Route>

    </Switch>
  );
}

export default Topics;
