import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
// import TitledText from '../TitledText';
import HowWorksSteps from './HowworksSteps';
import screen from '../../media/home-cover.svg';
import AppSection from '../AppSection';

function AppPage() {
  const { t } = useTranslation();
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };
  const words = ['IOS', 'Android'];
  return (
    <>
      <AppSection
        image={screen}
        title={t('app.title')}
        text={t('app.description')}
        titleLevel={1}
        word={words}
        placeholder={t('app.emailplaceholder')}
        btnh={t('app.btnnotify')}
      />
      <Row className="justify-content-center my-5">
        <Col xs={10}>
          <HowWorksSteps />
        </Col>
      </Row>
    </>
  );
}

export default AppPage;
