import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import TitledText from './TitledText';
import appStore from '../media/app-store.png';
import googlePlay from '../media/google-play.png';

function AppSection({
                      image,
                      classNameimage,
                      title,
                      text,
                      titleLevel,
                      align,
                      word,
                      className,
                      placeholder,
                      btnh
                    }) {

  /* const [email, setEmail] = useState('');
     const handleSubmit = (e) => {
       e.preventDefault();

       db.collection('betaRequest')
         .add({
           email: email
         })
         .then(() => {
           alert('işlem başarılı');
         })
         .catch(() => {
           alert('işlem başarısız');
         });
       setEmail('');
     };
    */


  return (
    <Row className='app-section__container d-flex justify-content-center my-3'>
      <Col xs={10}>

        <Row>
          <Col xs={12} md={6} className='d-flex justify-content-center px-lg-5'>
            <img
              src={image}
              className={`${classNameimage} img-fluid mx-auto`}
              alt='App Screen'
            />
          </Col>
          <Col xs={12} md={6} className='d-flex align-items-center px-5 mt-5 '>
            <Row className='flex-fill'>
              <Col>
                <Row>
                  <Col>
                    <TitledText
                      title={title}
                      titleLevel={titleLevel}
                      align={align}
                      className={className}
                      ishighlight={true}
                      word={word}
                    />
                  </Col>
                </Row>
                {/*
                <p className="text-body">{text}</p>
                <Row className="d-flex justify-content-center my-5">
                  <Col xs={12}>
                    <Form
                      onSubmit={handleSubmit}
                      className="px-1"
                     // action="https://smartforms.dev/submit/5ef3ad5e8720a934045a1a3a"
                     // method="POST"
                    >
                      <Row>
                        <Col
                          xs={12}
                          className="text-center d-md-flex justify-content-between"
                        >
                          <Form.Control
                            className="my-4 md-2"
                            placeholder={placeholder}
                            name="email"
                            value={email}
                            onChange={(e) =>setEmail(e.target.value)}
                          />
                          <Button
                            type="submit"
                            className="my-4 md-2 p-2 text-center text-nowrap"
                          >
                            {btnh}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                 */}
                <Col>
                  <Row>
                    <div className='store-image'>
                      <a href='https://apps.apple.com/tr/app/reach4health/id1597464512?l'>
                        <img src={appStore} alt='App Store' />
                      </a>
                    </div>
                    <div className='store-image'>
                      <a href='https://play.google.com/store/apps/details?id=com.naylalabs.reach'>
                        <img src={googlePlay} alt='Google Play' />
                      </a>
                    </div>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>

  );
}

AppSection.propTypes = {
  image: PropTypes.string,
  classNameimage: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleLevel: PropTypes.number,
  align: PropTypes.string,
  word: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  btnh: PropTypes.string
};

AppSection.defaultProps = {
  image: '../../media/group.png',
  classNameimage: null,
  text: null,
  titleLevel: 1,
  align: null,
  word: [],
  className: null,
  placeholder: 'submit your message',
  btnh: 'notify me'
};

export default AppSection;
