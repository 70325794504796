import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../../style/topic.css';
import TitledText from '../TitledText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TopicLinks({ resources }) {
  return (
    <>
      <TitledText title='Links' titleLevel={2} className='d-sm-none mt-4' />
      <Row className="py-0 px-0">
        <Col xs={12} className='links-border'>
          {resources.map((item, index) => (
            <a key={index} href={item.link} className='subtopic-links '>
              <div className="subtopic-border d-flex justify-content-between align-items-center py-4 px-0">
                <p className="mb-0 pl-3">{item.title}</p>
                <FontAwesomeIcon className={'icon-design mr-3'} icon={['fas', 'arrow-right']} />
              </div>
            </a>
          ))}
        </Col>
      </Row>
    </>
  );
}

export default TopicLinks;
