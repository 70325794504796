import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Carousel from '@brainhubeu/react-carousel';
import TitledText from '../TitledText';
import quoteLtr from '../../media/quote-ltr.svg';
import quoteRtl from '../../media/quote-rtl.svg';
import arrow from '../../media/arrow-right-pink.png';
import '../../style/carousel.css';

export default function Quotes() {
  const { i18n, t } = useTranslation();
  const carouselSettings = {
    centered: true,
    arrowLeft: (
      <img alt="carousel arrow" className="slider-arrow-right" src={arrow} />
    ),
    arrowRight: (
      <img alt="carousel arrow" className="slider-arrow-left" src={arrow} />
    ),
    addArrowClickHandler: true,
    infinite: true,
    slidesPerPage: 1,
    rtl: i18n.language === 'ar',
  };
  return (
    <Row className="justify-content-center secondary-bg">
      <Col xs={8} className="text-center mt-5">
        <img src={i18n.language === 'ar' ? quoteRtl : quoteLtr} alt="Quote" />
      </Col>
      <Col xs={12} md={8} className="pb-5 text-center">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Carousel className="quotes-carousel text-center" {...carouselSettings}>
          {t('about.quotes', {returnObjects: true}).map((quote, index) => (
            <TitledText
              key={index}
              title={t(`about.quotes.${index}.text`)}
              text={ t(`about.quotes.${index}.source`, { returnObjects: true }).join('<br/>') }
              titleLevel={2}
              align="center"
            />
          ))}
        </Carousel>
      </Col>
    </Row>
  );
}
