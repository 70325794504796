import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TitledText from './TitledText';
import cat1 from '../media/clipboard.svg';
import cat2 from '../media/stethoscope.svg';
import cat3 from '../media/apple.svg';
import cat4 from '../media/head.svg';
import cat5 from '../media/social.svg';
import cat6 from '../media/trees.svg';
import { Link } from 'react-router-dom';

const categoryImages = [cat1, cat3, cat2, cat4, cat5, cat6];

function Categories({keyword}) {
  const { t, i18n } = useTranslation();
  const categories = t('topics.categories.topics', { returnObjects: true });
  const [filteredCategories, setFilteredCategories] = useState(categories);

  useEffect(() => {
    const _cats = t('topics.categories.topics', { returnObjects: true }).filter(
      item => keyword && !keyword.length ? true : item.title.toLowerCase().indexOf(keyword) > -1
    );
    setFilteredCategories(_cats);
  }, [keyword, i18n.language, t]);

  // eslint-disable-next-line object-curly-newline
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={10} lg={6} className="text-center">
          <TitledText title={t('topics.categories.title')} titleLevel={2} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row className="justify-content-center my-5">
            {filteredCategories.length ? filteredCategories.map((cat, index) => (
              <Col xs={12} md={5} lg={3} className="m-3">
                <Link to={cat.url} className='category-link' >
                <div key={index} className="categories text-center p-4">
                  <div className="d-flex align-items-center justify-content-center">
                    {categoryImages[index] ?
                    <img src={categoryImages[index]} alt={cat.title} />
                      :   <img src={categoryImages[0]} alt={cat.title} />
                    }
                  </div>
                  <TitledText
                    title={t(`${cat.title}`)}
                    text={t(`${cat.description}`)}
                    titleLevel={4}
                  />
                </div>
                </Link>
              </Col>
            )) : <span>Not found!</span>}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Categories;
