import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NewsCard({ main, index, post }) {
  const showCard = () => {
    if (main) {
      return (
        <Link
          key={index}
          className="news-content news-carousel m-3 w-100 row"
          to={`/newspage/${post.id}`}
        >
          <Col
            xs={12}
            className="post-cover"
            style={{
              backgroundImage: `url(${post.media})`,
            }}
          >
            <p className="post-category px-3" style={{ bottom: `0` }}>
              {post.categoryLanguage}
            </p>
            {/* <img src={post.cover} alt={post.title} /> */}
          </Col>
          <Col xs={12} className="news-title d-flex align-items-center py-3">
            <h4 className="text-truncate text-blue">{post.title}</h4>
          </Col>
          <Col xs={12} className="d-flex align-items-center news-content ">
            {/* <p className="news-content">{post.desc}</p> */}
            <p
              className="news-content"
              dangerouslySetInnerHTML={{ __html: post.excerpt }}
            />
          </Col>
        </Link>
      );
    }
    return (
      <Card className="news-carousel news-card d-flex p-3 mx-1 bg-gray">
        <Card.Body>
          <p className="post-category px-3 my-3" style={{ top: `0` }}>
            {post.categoryLanguage}
          </p>
        </Card.Body>
        <Card.Body>
          <Card.Title>{post.title}</Card.Title>
          {/* <Card.Text className="news-content">{post.desc}</Card.Text> */}
          <Card.Text>
            <Link
              className="news-content"
              key={index}
              to={`/newspage/${post.id}`}
            >
              <p dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </Link>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return <>{showCard()}</>;
}

export default NewsCard;
