import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, Col, Row, NavDropdown } from 'react-bootstrap';
import logo from '../../media/logos/Logo_Final-01.svg';
import { Context } from '../../ContextProvider';

function Navigation() {
  const { i18n, t } = useTranslation();
  const [country, setCountry] = useState('Tr');
  const activeLink = {
    color: '#ff9385'
  };
  const selectLanguage = (language) => {
    selectLanguageWithCountry(language + country);
  };
  const selectLanguageWithCountry = (language) => {
    if (language.indexOf(/^(ar)/) > -1) {
      document.documentElement.style.setProperty('--direction', 'rtl');
      document.documentElement.style.setProperty('--align', 'right');
    } else {
      document.documentElement.style.setProperty('--direction', 'ltr');
      document.documentElement.style.setProperty('--align', 'left');
    }
    i18n.changeLanguage(language);
    const matches = language.match(/^(en|tr|ar|lb|jor)(.*)$/);
    setCountry(matches[2] || 'Tr');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <Context.Consumer>
        {(context) => (
          <>
            <Navbar
              data-testid='nav-parent'
              collapseOnSelect
              expand='lg'
              className='header d-lg-none p-0'
            >
              <Row className='w-100 m-0'>
                <Col xs={3} className='d-flex align-items-center p-0'>
                  <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                </Col>
                <Col xs={6} className='d-flex justify-content-center'>
                  <Navbar.Brand>
                    <NavLink exact to='/'>
                      <img className='logo' src={logo} alt='REACH-logo' />
                    </NavLink>
                  </Navbar.Brand>
                </Col>
              </Row>
              <Navbar.Collapse
                id='responsive-navbar-nav'
                className='justify-content-center'
              >
                <Nav className='navbar-bg container text-center'>
                  <NavLink exact to='/' activeStyle={activeLink}>
                    {t('nav.header.home')}
                  </NavLink>
                  <NavLink exact to='/about' activeStyle={activeLink}>
                    {t('nav.header.about')}
                  </NavLink>
                  <NavLink exact to='/health-provider' activeStyle={activeLink}>
                    {t('nav.header.healthProviders')}
                  </NavLink>
                  <NavLink exact to='/topics' activeStyle={activeLink}>
                    {t('nav.header.topics')}
                  </NavLink>
                  {/*
            <NavLink exact to="/providers" activeStyle={activeLink}>
              {t('nav.header.providers')}
            </NavLink> */}
                  <NavLink exact to='/app' activeStyle={activeLink}>
                    {t('nav.header.app')}
                  </NavLink>
                  {/* <NavLink exact to="/faqs" activeStyle={activeLink}>
              {t('nav.header.faq')}
            </NavLink> */}
                  {i18n.language !== 'ar' && (
                    <NavLink exact to='/news' activeStyle={activeLink}>
                      {t('nav.header.news')}
                    </NavLink>
                  )}
                  <NavLink exact to='/contact' activeStyle={activeLink}>
                    {t('nav.header.contact')}
                  </NavLink>
                  <NavDropdown
                    id='collasible-nav-dropdown'
                    title={t('nav.footer.language')}
                    lang={i18n.language}
                  >
                    <NavDropdown.Item onClick={() => selectLanguage('ar')}>
                      العربية
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => selectLanguage('tr')}>
                      Türkçe
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => selectLanguage('en')}>
                      English
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Row className='header d-none d-lg-flex justify-content-between align-items-center'>
              <Col md={4}>
                <NavLink exact to='/'>
                  <img className='logo' src={logo} alt='REACH-logo' />
                </NavLink>
              </Col>
              <Col md={6} xl={6} className='d-flex justify-content-end'>
                <NavLink
                  exact
                  to='/about'
                  activeStyle={activeLink}
                  className='p-2 text-nowrap'
                >
                  {t('nav.header.about')}
                </NavLink>
                <NavLink to='/health-providers' className='p-2 text-center text-nowrap' activeStyle={activeLink}>
                  {t('nav.header.healthProviders')}
                </NavLink>
                <NavLink to='/topics' className='p-2 text-center text-nowrap' activeStyle={activeLink}>
                  {t('nav.header.topics')}
                </NavLink>
                {/*
          <NavLink to="/providers" activeStyle={activeLink}>
            {t('nav.header.providers')}
          </NavLink> */}
                <NavLink
                  exact
                  to='/app'
                  activeStyle={activeLink}
                  className='p-2 text-center text-nowrap'
                >
                  {t('nav.header.app')}
                </NavLink>
                {/* <NavLink exact to="/faqs" activeStyle={activeLink}>
            {t('nav.header.faq')}
          </NavLink> */}
                {i18n.language !== 'ar' && (
                  <NavLink
                    exact
                    to='/news'
                    activeStyle={activeLink}
                    className='p-2 text-center text-nowrap'
                  >
                    {t('nav.header.news')}
                  </NavLink>
                )}
                <NavLink
                  exact
                  to='/contact'
                  activeStyle={activeLink}
                  className='p-2 text-center text-nowrap'
                >
                  {t('nav.header.contact')}
                </NavLink>
                <NavDropdown
                  id='collasible-nav-dropdown'
                  title={t('nav.footer.language')}
                  lang={i18n.language}
                >
                  <NavDropdown.Item onClick={() => selectLanguage('ar')}>
                    العربية
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => selectLanguage('tr')}>
                    Türkçe
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => selectLanguage('en')}>
                    English
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  id='collasible-nav-dropdown'
                  title={t('nav.countries.' + context.global.countryLabel)}
                >
                  <NavDropdown.Item onClick={() => {
                    context.changeGlobal({ country: 'tr', countryLabel: 'turkey' });
                    selectLanguageWithCountry(t('nav.langCodeWithCountries.tr'));
                  }}>
                    {t('nav.countries.turkey')}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => {
                    context.changeGlobal({ country: 'lb', countryLabel: 'lebanon' });
                    selectLanguageWithCountry(t('nav.langCodeWithCountries.lb'));
                  }}>
                    {t('nav.countries.lebanon')}
                  </NavDropdown.Item>
                </NavDropdown>
              </Col>
            </Row>
          </>
        )}
      </Context.Consumer>
    </>
  );
}

export default Navigation;
