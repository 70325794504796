import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import TitledText from '../TitledText';

function FaqHeader() {
  const { t } = useTranslation();

  return (
    <>
      <Row className="justify-content-center py-5 bg-gray">
        <Col xs={11} md={10} lg={6} className="my-5 text-center">
          <TitledText
            title={t('faqs.header.title')}
            text={t('faqs.header.description')}
            titleLevel={3}
          />
        </Col>
      </Row>
    </>
  );
}

export default FaqHeader;
