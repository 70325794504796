import React, { useLayoutEffect, useEffect, useState } from 'react';
import {
  Route,
  Switch,
  Redirect, BrowserRouter
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircle,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import Header from './components/Header';
import HomePage from './components/Home';
import About from './components/About';
import Topics from './components/HealthTopics';
import Topic from './components/HealthTopics/Topic';
import AppPage from './components/AppPage';
import ContactPage from './components/Contact';
import Footer from './components/Footer';
import News from './components/News';
import Newspage from './components/News/Newspage';
import PageNotFound from './components/PageNotFound';
import ReachFAQs from './components/Faq';
import Subtopic from './components/HealthTopics/Subtopic';
import HealthServicesIndex from './components/HealthServices';
import FaqIndex from './components/Faqs';
import HospitalDetail from './components/HospitalDetail';
import { ContextProvider } from './ContextProvider';
import TermsAndConditions from './components/ terms-and-conditions';
import PrivacyPolicy from './components/privacy-policy';

function App() {
  const { i18n } = useTranslation();
  const [classNameLanguage, setClassNameLanguage] = useState();
  useLayoutEffect(() => {
    if (i18n.language.indexOf(/^(ar)/) > -1) {
      document.documentElement.style.setProperty('--direction', 'rtl');
      document.documentElement.style.setProperty('--align', 'right');
      setClassNameLanguage('');
    } else if (i18n.language.indexOf(/^(tr)/) > -1) {
      setClassNameLanguage('turkishFont');
      document.documentElement.style.setProperty('--direction', 'ltr');
      document.documentElement.style.setProperty('--align', 'left');
    } else {
      document.documentElement.style.setProperty('--direction', 'ltr');
      document.documentElement.style.setProperty('--align', 'left');
      setClassNameLanguage('');
    }
  }, [i18n.language]);
  const [posts, setPosts] = useState([]);
  const url =
    'https://public-api.wordpress.com/wp/v2/sites/reach4healthapp.wordpress.com/posts/?categories=3205';
  const fetchBlogPosts = async () => {
    const blogsApiWithLangParam = url;
    const data = await fetch(blogsApiWithLangParam);
    const fetchedBlogPosts = await data.json();
    setPosts(
      fetchedBlogPosts.map((el) => {
        return {
          id: el.id,
          title: el.title.rendered,
          excerpt: el.excerpt.rendered,
          content: el.content.rendered,
          media: el.jetpack_featured_media_url,
          category: el.tags.find((tag) => tag === 3867) ? 'Article' : 'Event',
        };
      })
    );
  };
  useEffect(() => {
    fetchBlogPosts();
  }, []);
  // Add needed fontawesome icons to library
  library.add(
    faCircle,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faWhatsapp,
    faTwitter
  );

  return (
    <div className={`container-fluid px-0 ${classNameLanguage}`}>
      <ContextProvider>
        <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" render={() => <HomePage {...posts} />} />
          <Route exact path="/about" render={() => <About />} />
          <Route path="/topics" render={() => <Topics />} />
          <Route path="topics/:topicId" render={() => <Topic />} />
          <Route path="topics/:topicId/:subTopicId" render={() => <Subtopic />} />
          <Route exact path="/health-providers" render={() => <HealthServicesIndex/>}/>
          <Route exact path="/faq" render={() => <FaqIndex></FaqIndex>}></Route>
          <Route exact path="/provider-detail/:hospitalId" render={() => <HospitalDetail></HospitalDetail>}></Route>
          <Route exact path="/app" render={() => <AppPage />} />
          <Route exact path="/news" render={() => <News {...posts} />} />
          <Route exact path="/terms-and-conditions" render={() => <TermsAndConditions></TermsAndConditions>} />
          <Route exact path="/privacy-policy" render={() => <PrivacyPolicy></PrivacyPolicy>} />
          <Route
            exacts
            path="/newspage/:id"
            render={(props) => <Newspage {...props} />}
          />
          <Route expect path="/faqs" render={() => <ReachFAQs />} />
          <Route exact path="/contact" render={() => <ContactPage />} />
          <Route path="/404" render={() => <PageNotFound />} />
          <Redirect to="/404" />
        </Switch>
        <Footer />
      </BrowserRouter>
      </ContextProvider>
    </div>
  );
}

export default App;
