import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'react-bootstrap';
import '../../style/contact.css';
import { db } from '../../firebase';

function ContactPage() {
  const { t, i18n } = useTranslation();
  const handleFocus = (e) => {
    const input = e.target;
    const label = document.querySelector(`#label-${input.id.slice(6)}`);
    label.classList.add('active');
  };

  const handleBlur = (e) => {
    const input = e.target;
    const label = document.querySelector(`#label-${input.id.slice(6)}`);
    label.classList.remove('active');
    if (input.value) {
      label.classList.add('filled');
    } else {
      label.classList.remove('filled');
    }
  };

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surName, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [question, setQuestion] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('questions')
      .add({
        email: email,
        name: name,
        phone: phone,
        question: question,
        surName: surName,
        web: true
      })
      .then(() => {
        alert('işlem başarılı');
      })
      .catch(() => {
        alert('işlem başarısız');
      });
    setEmail('');
    setName('');
    setPhone('');
    setQuestion('');
    setSurname('');
  };

  return (
    <div className='p-3 depth-shadow'>
      <Row className='justify-content-center'>
        <Col md={{ span: 8 }}>
          <Form
            onSubmit={handleSubmit}
            id='contact-form'
            autoComplete='off'
            lang={i18n.language}
          >
            <Row className='pt-2 pt-md-4 px-3'>
              <Col xs={12} md={6} className='p-0'>
                <Form.Group controlId='input-name' className='m-2'>
                  <Form.Label id='label-name'>{t('contact.name')}</Form.Label>
                  <Form.Control
                    type='text'
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    size='lg'
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className='p-0'>
                <Form.Group controlId='input-surname' className='m-2'>
                  <Form.Label id='label-surname'>
                    {t('contact.surname')}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    required
                    value={surName}
                    onChange={(e) => setSurname(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    size='lg'
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className='p-0'>
                <Form.Group controlId='input-email' className='m-2'>
                  <Form.Label id='label-email'>{t('contact.email')}</Form.Label>
                  <Form.Control
                    type='email'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    size='lg'
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className='p-0'>
                <Form.Group controlId='input-phone' className='m-2'>
                  <Form.Label id='label-phone'>{t('contact.phone')}</Form.Label>
                  <Form.Control
                    type='tel'
                    // TODO: revisit phone validation pattern
                    //  pattern="[0-9٠-٩]{3}-[0-9٠-٩]{3}-[0-9٠-٩]{4}"
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    size='lg'
                  />
                </Form.Group>
              </Col>
              {/*
              <Col xs={12} md={12} className="p-0">
                <Form.Group controlId="input-organization" className="m-2">
                  <Form.Label id="label-organization">
                    {t('contact.organization')}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    size="lg"
                  />
                </Form.Group>
              </Col>
              */}
              {/* <Col xs={12} md={6} className="p-0">
                <Form.Group controlId="input-interest" className="m-2">
                  <Form.Label id="label-interest">
                    {t('contact.interest')}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    size="lg"
                  >
                    <option hidden disabled selected value />
                    <option>Interest 1</option>
                    <option>Interest 2</option>
                    <option>Interest 3</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
              <Col xs={12} className='p-0'>
                <Form.Group controlId='input-message' className='m-2'>
                  <Form.Label id='label-message'>
                    {t('contact.message')}
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='5'
                    required
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    size='lg'
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='text-center'>
                <button
                  type='submit'
                  size='lg'
                  className='btn p-2 my-4'
                >
                  {t('contact.submit')}
                </button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default ContactPage;
