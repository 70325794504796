import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TitledText from '../TitledText';
import Categories from '../Categories';
import how1 from '../../media/how1.png';
import how2 from '../../media/how2.png';
import how3 from '../../media/how3.png';
import AboutApp from '../about-app';

function HowWorksSteps() {
  const { t } = useTranslation();
  const [keyword] = useState('');

  return (
    <>
      <Row className="how-section d-flex justify-content-center my-5">
        <Col xs={12}>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={6}>
              <Row className="px-lg-5">
                <Col xs={4} md={2} className="num-header">
                  1
                </Col>
                <Col xs={8} md={10}>
                  <TitledText
                    title={t('home.app.features.0.name')}
                    titleLevel={3}
                    text={t('home.app.features.0.description')}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex align-items-centre justify-content-center"
            >
              <div className={'app-image'}>
                <img
                  src={how1}
                  alt="How REACH Works"
                  className="img-fluid mx-auto"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="how-section d-flex justify-content-center my-5">
        <Col xs={12}>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={6} lg={6}>
              <Row className="px-lg-5 mt-5">
                <Col xs={4} md={4} className="num-header">
                  2
                </Col>
                <Col xs={8} md={8}>
                  <TitledText
                    title={t('home.app.features.1.name')}
                    titleLevel={3}
                    text={t('home.app.features.1.description')}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              className="d-flex align-items-centre justify-content-center order-md-first"
            >
              <div  className={'app-image'}>
                <img
                  src={how2}
                  alt="How REACH Works"
                  className="img-fluid mx-auto"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="how-section d-flex justify-content-center my-5">
        <Col xs={12}>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={8} lg={8}>
              <Row className="px-lg-5 my-auto">
                <Col xs={4} md={2} lg={2} className="num-header">
                  3
                </Col>
                <Col xs={8} md={6} lg={6}>
                  <TitledText
                    title={t('home.app.features.2.name')}
                    titleLevel={3}
                    text={t('home.app.features.2.description')}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={4}
              lg={4}
              className="d-flex align-items-centre justify-content-center"
            >
              <div className='app-image'>
              <img
                src={how3}
                alt="How REACH Works"
                className="img-fluid mx-auto"
              />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Categories keyword={keyword} />
      <AboutApp></AboutApp>
    </>
  );
}

export default HowWorksSteps;
