import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

function TitledText({
  title,
  highlight,
  text,
  textLinked,
  titleLevel,
  align,
  className,
  isMainDescription,
  isSecondaryDescription,
}) {
  const Header = `h${titleLevel}`;
  const padding = 4;
  // const Span = `<span>${highlight}</span>`
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  i18next.on('languageChanged', (lng) => {
    setCurrentLanguage(lng);
  });
  const linkedTextlang = () => {
    switch (currentLanguage) {
      case 'arTr':
      case 'arLb':
        return (
          <p
            className={`my-2 my-md-${padding} ${align ? `text-${align}` : ''} ${
              isMainDescription ? 'main-description' : 'standart-description'
            } ${isSecondaryDescription ? 'secondary-description' : ''}`}
            data-testid="titled-text-paragraph"
          >
            تم إنشاء REACH من قبل{' '}
            <a
              href="https://www.earth.columbia.edu/users/profile/yanis-ben-amor"
              target="_blanc"
              className="text-body"
              style={{ textDecoration: 'none'}}
            >
              {' '}
              د.يانيس بن عمر{' '}
            </a>{' '}
            وزملائه
            . يستخدم المشروع بحوث العمل التشاركية المجتمعية (CBPAR) الذي يمكّن
            المستخدمين من المشاركة في الإنشاء للمنتج. يهدف REACH إلى تقييم تأثير
            التكنولوجيا على الوصول إلى المعلومات الصحية الموثوقة والخدمات الصحية
            في البلدان التي تستضيف أعدادًا كبيرة من اللاجئين.
          </p>
        );
      case 'trLb':
      case 'trTr':
        return (
          <p
            className={`my-2 my-md-${padding} ${align ? `text-${align}` : ''} ${
              isMainDescription ? 'main-description' : 'standart-description'
            } ${isSecondaryDescription ? 'secondary-description' : ''}`}
            data-testid="titled-text-paragraph"
          >
            REACH{' '}
            <a
              href="https://www.earth.columbia.edu/users/profile/yanis-ben-amor"
              target="_blanc"
              className="text-body"
              style={{ textDecoration: 'underline' }}
            >
              {' '}
              Dr. Yanis Ben Amor
            </a>{' '}
            ve iş arkadaşları tarafından geliştirilen bölgesel bir girişimdir. Bu proje,
            katılımcıların tüm sürece dahil olmasına imkan tanıyan Toplum
            Temelli Katılımcı Eylem Araştırması yöntemi ile yürütülmektedir.
            REACH, yüksek sayıda mülteci nüfusa ev sahipliği yapan ülkelerde
            gençlerin güvenilir sağlık bilgisine ve sağlık hizmetlerine
            erişiminde teknolojinin etkisini ölçmeyi amaçlamaktadır.
          </p>
        );

      default:
        return (
          <p
            className={`my-2 my-md-${padding} ${align ? `text-${align}` : ''} ${
              isMainDescription ? 'main-description' : 'standart-description'
            } ${isSecondaryDescription ? 'secondary-description' : ''}`}
            data-testid="titled-text-paragraph"
          >
            REACH was created by {' '}
            <a
              href="https://www.earth.columbia.edu/users/profile/yanis-ben-amor"
              target="_blanc"
              className="text-body"
              style={{ textDecoration: 'none' }}
            >
              {' '}
              Dr. Yanis Ben Amor{' '}
            </a>{' '}
            and colleagues. The project employs Community Based Participatory Action Research
            (CBPAR) framework which enables users to be involved in the creation
            of the product. REACH aims to assess the impact of technology on
            accessing reliable health information and health services in
            countries that host large refugee populations.
          </p>
        );
    }
  };

  return (
    <div className={`titled-text ${className !== null ? className : ''}`}>
      <Header
        className={`standart-description my-2 my-md-${padding} ${align ? `text-${align}` : ''}`}
        data-testid="titled-text-title"
        id="header-title"
      >
        {title}{' '}
        <span className={highlight ? 'highlight-pink' : ''}>{highlight}</span>
      </Header>
      {text ? (
        <p
          className={`my-2 my-md-${padding} ${align ? `text-${align}` : ''} ${
            isMainDescription ? 'main-description' : 'standart-description'
          } ${isSecondaryDescription ? 'secondary-description' : ''}`}
          data-testid="titled-text-paragraph"
          dangerouslySetInnerHTML={{__html: text}}>
        </p>
      ) : null}
      {textLinked ? linkedTextlang() : null}
    </div>
  );
}

TitledText.propTypes = {
  title: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleLevel: PropTypes.number,
  align: PropTypes.string,
  className: PropTypes.string,
  isMainDescription: PropTypes.bool,
  ishighlight: PropTypes.bool,
  word: PropTypes.array,
};

TitledText.defaultProps = {
  text: null,
  highlight: null,
  titleLevel: 1,
  align: null,
  className: null,
  isMainDescription: null,
  isSecondaryDescription: null,
};

export default TitledText;
