import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Accordion from '../Accordion';
import { useTranslation } from 'react-i18next';

function FaqItem({ topic }) {
  const { t } = useTranslation();
  const faqs = t('faqs', { returnObjects: true });
  const filteredFaq = faqs.filter(
    item => item.services.some(serviceId => topic.subTopics.map(sb => sb.key).includes(
      serviceId
    ))
  );
  return (
    <>
      <Row className='py-0 px-0'>
        <Col className='col-12'>
          {filteredFaq.length > 0 ?
          <Accordion filteredFaq={filteredFaq} /> : <span className='faq-not-found'>{t('notFounds.faqs')}</span>
          }
        </Col>
      </Row>
    </>
  );
}

export default FaqItem;
