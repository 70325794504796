import React, { createContext, useState } from 'react';

export const Context = createContext();

export const ContextProvider = (props) => {
  const [global, setGlobal] = useState({ country: 'tr', countryLabel: 'turkey' });
  return (
    <Context.Provider value={{ global, changeGlobal: setGlobal }}>
      {props.children}
    </Context.Provider>
  )
}
