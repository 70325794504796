import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';
import GoogleMapReact from 'google-map-react';
import MapIcon from '../../media/map-icon.png';
import PhoneIcon from '../../media/phone-icon.png';
import { Link } from 'react-router-dom';
import { Context } from '../../ContextProvider';
import getDistanceBetweenPoints from 'get-distance-between-points';
import { useTranslation } from 'react-i18next';
import { useVirtualizer } from "@tanstack/react-virtual";

const MapMarker = ({ text }) => <img src={MapIcon} alt='Povider Marker'/>;

function TopicServices({ providers }) {
  const parentRef = useRef(null);
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [coordinates, setCoordinates] = useState({
    center: {
      lat: 0,
      lng: 0
    },
    zoom: 11
  });
  /** GELOCATION **/
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(false);
  /** E:GELOCATION **/

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus(false);
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(true);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      }, () => {
        setStatus(false);
      });
    }
  }

  useEffect(()=> {
    getLocation()
  }, []);

  useEffect(() => {
    const firstProvider = providers[0] || null;
    if (firstProvider) {
      changeMap(firstProvider.lat, firstProvider.lng, 0);
    }
  }, [providers]);

  function changeMap(lat, lng, index) {
    setCoordinates({
      center: { lat: lat, lng: lng },
      zoom: 15
    });
    setSelectedIndex(index);
  }

  function getDistance(provider){
    if(!lat || !lng || !status) return '+99KM';
    const distance = getDistanceBetweenPoints.getDistanceBetweenPoints(
      lat,lng,provider.lat,provider.lng
    ) * 0.001;
    return Math.min(100,Math.floor(distance)).toFixed(2)+'KM'
  }

  const virtualizer = useVirtualizer({
    count: providers.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
  });

  return (
    <>
      <Context.Consumer>
        {(context) => {
          //providers = providers.filter(item => ('' || item.country.toUpperCase()) === context.global.country);
          return providers.length > 0 ?
            <Row className='align-items-stretch'>
              <Col className='health-providers col-lg-6 col-md-6 col-sm-12 bg-gray pr-3 py-3 mb-3'>
                <div className='hospitals'
                  ref={parentRef}
                  style={{ height: 800, overflowX: 'auto' }}>
                  <div
                    style={{
                      height: virtualizer.getTotalSize(),
                      width: '100%',
                      position: 'relative'
                    }}>
                    {virtualizer.getVirtualItems().map((virtualRow) => (
                      <div
                        key={virtualRow.key}
                        data-index={virtualRow.index}
                        ref={virtualizer.measureElement}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          transform: `translateY(${virtualRow.start}px)`,
                        }}
                      >
                        <div key={virtualRow.index}
                          onClick={() => changeMap(providers[virtualRow.index].lat, providers[virtualRow.index].lng, virtualRow.index)}
                            className='hospital my-3 py-2 col-12'>
                          <div className='hospital-images p-0 col-md-4 col-12'>
                            <img className='hospital-image' src={providers[virtualRow.index].image || "/hospital.png"} alt='Provider' />
                          </div>
                          <div className='hospital-informations col-md-7 col-12'>
                            <div className='rating mb-2'>
                              <StarRatings
                                rating={providers[virtualRow.index].rating}
                                numberOfStars={5}
                                name='rating'
                                starRatedColor={'#FF9D96'}
                                starEmptyColor={'#f7f7f7'}
                                starDimension={'20px'}
                                starSpacing={'2px'}
                              />
                            </div>
                            <div className='hospital-name mb-2'>
                              <p><strong>{providers[virtualRow.index].name}</strong></p>
                            </div>
                            <div className='sub-infos mb-2'>
                              <span className='sub-info pr-2'>{providers[virtualRow.index].type}</span>
                              {status && <span className={'sub-info pr-2'}>{getDistance(providers[virtualRow.index])} away</span>}
                              <span className='sub-info'>Open 24/7</span>
                            </div>
                            <div className='button-wrapper'>
                              <button className='phone-button'>
                                <img src={PhoneIcon} alt='Phone Icon' className='phone-icon' />
                                <span>{providers[virtualRow.index].tel}</span>
                              </button>
                              <Link className='topic-link-item' to={`/provider-detail/${providers[virtualRow.index].id}`}>
                                <button className='see-all' href={'/'}>
                                  See Details
                                </button>
                              </Link>
                            </div>
                          </div>
                          <div className='map-icon col-1 p-0'>
                            {selectedIndex === virtualRow.index &&
                            <div className={'google-map-icon-wrapper'}>
                              <img className={'google-map-icon'} src={MapIcon} alt='' />
                            </div>}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
              <Col className='map-google d-md-block d-none col-lg-6 col-m-6'>
                <div className='col-12 p-0 sticky-map'
                     style={{ height: '520px', borderRadius: '16px', overflow: 'hidden' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBbrn5BXNIB7w4ociY_17dDm6fsKCfgWSA' }}
                    defaultCenter={{ lat: 0, lng: 0 }}
                    defaultZoom={coordinates.zoom}
                    center={coordinates.center}
                  >
                    <MapMarker lat={coordinates.center.lat} lng={coordinates.center.lng}></MapMarker>
                  </GoogleMapReact>
                </div>
              </Col>
            </Row> : <div className='col-12 not-found my-5'><span>{t('notFounds.services')}</span></div>
        }}
      </Context.Consumer>
    </>
  );
}

export default TopicServices;
