import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TitledText from '../TitledText';

function FaqRow() {
  const { t } = useTranslation();

  return (
    <>
      <Row className="justify-content-center py-5 bg-light-blue">
        <Col xs={11} md={10} lg={6} className="my-5 text-center">
          <TitledText
            title={t('faqs.search.title')}
            text={
              <>
                <p className="text-center">{t('faqs.search.description1')}</p>
                <p className="text-center">
                  {t('faqs.search.description2')}
                </p>{' '}
              </>
            }
            titleLevel={3}
            align="center"
          />
          <Row className="justify-content-center">
            <Col
              xs={11}
              md={10}
              lg={6}
              className="d-flex justify-content-center"
            >
              <Link to="./contact" className="btn p-2 my-4">
                {t('faqs.search.button')}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default FaqRow;
