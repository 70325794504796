import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import NewsCover from './NewsCover';
import NewsMain from './NewsMain';
import AppSection from '../AppSection';
import NewsCarouselRow from '../NewsCarousel';
import screen from '../../media/newsscreen.svg';
// import { samplePostsEn, samplePostsTr } from './Posts';

function NewsPage() {
  const [category, setCategory] = useState(
    i18next.language === 'tr' ? '71316' : '3205'
  );
  const [language, setLanguage] = useState({
    article: 'Article',
    event: 'Event',
  });
  const [posts, setPosts] = useState([]);
  const url =
    'https://public-api.wordpress.com/wp/v2/sites/reach4healthapp.wordpress.com/posts';
  useEffect(() => {
    const fetchBlogPosts = async () => {
      const data = await fetch(`${url}/?categories=${category}`);
      const fetchedBlogPosts = await data.json();
      setPosts(
        fetchedBlogPosts.map((el) => {
          return {
            id: el.id,
            title: el.title.rendered.replace(/&nbsp;/g, ' '),
            excerpt: el.excerpt.rendered,
            content: el.content.rendered,
            media: el.jetpack_featured_media_url,
            category: el.tags.find((tag) => tag === 3867) ? 'Article' : 'Event',
            categoryLanguage: el.tags.find((tag) => tag === 3867)
              ? language.article
              : language.event,
          };
        })
      );
    };
    fetchBlogPosts();
  }, [category, language]);
  i18next.on('languageChanged', (lng) => {
    switch (lng) {
      case 'en':
        setCategory('3205');
        setLanguage({ article: 'Article', event: 'Event' });
        break;
      case 'tr':
        setCategory('71316');
        setLanguage({ article: 'Haber', event: 'Etkinlik' });
        break;
      default:
        setCategory('3205');
        break;
    }
  });
  const { t } = useTranslation();

  return (
    <>
      <NewsCover title={t('news.title')} description={t('news.description')} />

      <NewsCarouselRow
        title={t('news.selected-news.title')}
        posts={posts}
        button={t('news.selected-news.button')}
      />

      <NewsMain posts={posts} />

      <AppSection
        image={screen}
        classNameimage="newsexplore"
        title={t('about.explore.title')}
        text={t('about.explore.description')}
        titleLevel={3}
        placeholder={t('about.explore.placeholder')}
        btnh={t('about.explore.button')}
      />
    </>
  );
}

export default NewsPage;
