import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';


function AboutApp() {
  const { t } = useTranslation();
  return (
    <>
      <Row className="row justify-content-center my-5">
        <Col xs={10} sm={8} lg={6} xl={10} className="text-center">
          <div className='terms-text' dangerouslySetInnerHTML={{ __html: t('app.aboutApp') }}></div>
        </Col>
      </Row>
    </>
  );
}

export default AboutApp;
