import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button } from 'react-bootstrap';
import TitledText from '../TitledText';
import coverImage from '../../media/home-cover.svg';
import { db } from '../../firebase';

function HomeCover({ title, highlight, description, btnText, placeholder }) {
  const { i18n } = useTranslation();
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('betaRequest')
      .add({
        email: email
      });
    setEmail('');
  };
  return (
    <Row className='home-cover d-flex align-items-center justify-content-around pt-md-5 pt-3 pb-5'>
      {/*
        Bootstrap's .offset-{x} always adds space to the left, which doesn't work for RTL.
        Adding an empty column instead.
      */}
      {i18n.language === 'ar' ? <Col md={1} /> : null}
      <Col xs={8} xl={4} className='my-5'>
        <TitledText
          title={title}
          highlight={highlight}
          text={description}
          isMainDescription={true}
        />

        <Form
          className={'home-form-flex'}
          onSubmit={handleSubmit}
        >
          <Form.Group className='' controlId='formBasicEmail'>
            <Form.Control
              value={email}
              onChange={(e) =>setEmail(e.target.value)}
              type='email'
              placeholder={placeholder} />
          </Form.Group>
          <Button variant='primary' type='submit' className='py-2'>
            {btnText}
          </Button>
        </Form>
      </Col>
      <Col
        lg={12}
        xl={6}
        className={`d-flex justify-content-xl-end justify-content-center mt-lg-4
          ${i18n.language === 'ar' ? 'order-md-12' : null}`}
      >
        <img src={coverImage} alt='REACH App' />
      </Col>
    </Row>
  );
}

HomeCover.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  btnText: PropTypes.string.isRequired
};

export default HomeCover;
