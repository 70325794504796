import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import TitledText from '../TitledText';
import FaqList from './faq-list';

function FaqIndex() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  return (
    <Switch>
      <Route exact path={path}>
        <Row className='justify-content-center py-5 post-header topic-header'>
          <Col xs={10} lg={5} className='my-5 text-center'>
            <TitledText title={t('faqSection.title')} text={t('faqSection.description')} isMainDescription={true} titleLevel={2} />
          </Col>
        </Row>
        <FaqList/>
      </Route>
    </Switch>
  );
}

export default FaqIndex;
