import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import TitledText from '../TitledText';
import './HomeAbout.css';

function HomeAbout({ title, description }) {
  return (
    <Row className="justify-content-center mb-5 home-about bg-gray aboutBG">
      <Col xs={10} lg={6} className="my-5 text-center">
        <TitledText
          title={title}
          text={description}
          titleLevel={2}
          isSecondaryDescription={true}
        />
      </Col>
    </Row>
  );
}

HomeAbout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};

export default HomeAbout;
