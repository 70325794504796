import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TitledText from './TitledText';
import Search from './Search';

function TitleBar({ title, description, changeKeyword }) {
  return (
    <>
      <Row className="justify-content-center py-5 post-header topic-header">
        <Col xs={10} lg={5} className="my-5 text-center">
          <TitledText title={title} text={description} titleLevel={2} />
        </Col>
      </Row>
      <Search changeKeyword={changeKeyword}></Search>
    </>
  );
}

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  changeKeyword: PropTypes.func
};

export default TitleBar;
