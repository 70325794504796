import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Carousel from '@brainhubeu/react-carousel';
import TitledText from './TitledText';
import CustomCard from '../components/News/NewsCard';
import arrow from '../media/arrow-right-blue.png';

export function NewsCarouselSection({ children, posts }) {
  const { i18n } = useTranslation();
  const carouselSettings = {
    centered: true,
    infinite: true,
    clickToChange: true,
    slidesPerPage: 4,
    dots: false,
    rtl: i18n.language === 'ar',
    breakpoints: {
      // Small screens (<=767)
      767: {
        slidesPerPage: 1,
      },
      // Medium screens (<=991)
      991: {
        centered: false,
        slidesPerPage: 2,
      },
    },
    arrowLeft: (
      <img alt="carousel arrow" className="slider-arrow-right" src={arrow} />
    ),
    arrowRight: (
      <img alt="carousel arrow" className="slider-arrow-left" src={arrow} />
    ),
    addArrowClickHandler: true,
  };
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="text-center">
          {children}
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="news-carousel p-0">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Carousel {...carouselSettings}>
            {posts.slice(0, 8).map((post, index) => (
              <CustomCard main post={post} index={index} />
            ))}
          </Carousel>
        </Col>
      </Row>
    </>
  );
}

NewsCarouselSection.propTypes = {
  children: PropTypes.element.isRequired,
  posts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  button: PropTypes.string,
};

NewsCarouselSection.defaultProps = {
  button: null,
};

export default function NewsCarousel({ title, description, posts, button }) {
  return (
    <Row className="justify-content-center my-5">
      <Col xs={12}>
        <NewsCarouselSection posts={posts} button={button}>
          {title && (
            <TitledText
              title={title}
              text={description}
              titleLevel={3}
              className="primary-color"
            />
          )}
        </NewsCarouselSection>
      </Col>
    </Row>
  );
}

NewsCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  posts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  button: PropTypes.string,
};

NewsCarousel.defaultProps = {
  description: null,
  button: null,
};
