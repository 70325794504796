import React from 'react';
import { useTranslation } from 'react-i18next';
import cat1 from '../../media/clipboard.svg';
import cat2 from '../../media/stethoscope.svg';
import cat3 from '../../media/apple.svg';
import cat4 from '../../media/head.svg';
import cat5 from '../../media/social.svg';
import cat6 from '../../media/trees.svg';
import FaqImage from '../../media/faq-image.png';
import FaqItem from './FaqItem';

function FaqList() {
  const categoryImages = [cat1, cat3, cat2, cat4, cat5, cat6];
  const { t } = useTranslation();
  const providerServices = t('topics.categories.topics', { returnObjects: true });
  return (
    <>
      <div className='faq-page row'>
        <div className='faq-form-wrapper'>
          <div className='container'>
            <div className='filter-form d-flex col-md-9 col-12 flex-md-row flex-column m-auto'>
              {/* SEARCH */}
              <div className='faq-items col-12 col-md-12 px-0'>
                <input
                  placeholder='Search in service providers...'
                  type='text'
                  className='search-box w-100'
                />
              </div>
              {/* E:SEARCH */}
            </div>
            {/* FAQ */}
            <div className='faq-list col-12'>
              {providerServices.map((item, index) => {
                return (
                  <div className='provider'>
                    <div className='provider-detail col-md-5 col-12' key={index}>
                      <div className='provider-image'>
                        {categoryImages[index] ?
                          <img className='image-item' src={categoryImages[index]} alt='Provider Service' /> :
                          <img className='image-item' src={categoryImages[0]} alt='Provider Service' />
                        }
                      </div>
                      <div className='provider-detail-title ml-4'>
                        <p className='title'>{item.title}</p>
                      </div>
                    </div>
                    <div className='provider-faq col-md-7 col-12'>
                      <FaqItem topic={item}></FaqItem>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* E:FAQ */}
          </div>
          <div className='faq-contact-wrapper'>
            <div className='contact-image  col-md-6 col-12'>
              <div className='image-wrapper'>
                <img className='image-item' src={FaqImage} alt='Faq Contact' />
              </div>
            </div>
            <div className='contact-text-wrapper  col-md-6 col-12'>
              <div className='contact-text'>
                <h3 className='contact-text-title'>{t('faqFooter.title')}</h3>
                <p className='contact-text-description'>
                  {t('faqFooter.description')}
                </p>
                <a href='/contact' className='text-button'>
                  {t('faqFooter.button')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqList;
