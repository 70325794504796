import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
// import Carousel from '@brainhubeu/react-carousel';
import TitledText from './TitledText';
// import arrow from '../media/arrow-right-blue.png';
import '../style/carousel.css';
import naylalabs from '../media/logos/naylalabs.png';

export function LogoCarouselSection({ children, images }) {
  // const { i18n } = useTranslation();

  // const carouselSettings = {
  //   arrowLeft: (
  //     <img alt="carousel arrow" className="slider-arrow-right" src={arrow} />
  //   ),
  //   arrowRight: (
  //     <img alt="carousel arrow" className="slider-arrow-left" src={arrow} />
  //   ),
  //   addArrowClickHandler: true,
  //   infinite: true,
  //   clickToChange: true,
  //   draggable: false,
  //   slidesPerPage: 4,
  //   rtl: i18n.language === 'ar',
  //   breakpoints: {
  //     // Small screens (<=767)
  //     767: {
  //       slidesPerPage: 2,
  //     },
  //     // Medium screens (<=991)
  //     991: {
  //       slidesPerPage: 3,
  //     },
  //   },
  // };

  // const slides = images.reduce((accumulator, currentValue, index, array) => {
  //   if (index % width === 0) {
  //     accumulator.push(array.slice(index, index + width));
  //   }
  //   return accumulator;
  // }, []);

  return (
    <>
      <Row className="justify-content-center">
        <Col xs={10} lg={5} className="px-1 pt-4 text-center">
          {children}
        </Col>
      </Row>
      <Row className="d-flex">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {/* <Carousel {...carouselSettings}> */}
        {/* {slides.map((slide, index) => (
          <Row key={index} className="justify-content-center w-100">
            {slide.map((image, arrIndex) => (
              <Col
                key={arrIndex}
                xs={4}
                className="d-flex align-items-center justify-content-center py-3"
              >
                <img src={image} alt="logo" />
              </Col>
            ))}
          </Row>
        ))} */}
        {images.map((image, index) => (
          // eslint-disable-next-line object-curly-newline
          <div
            className={"justify-content-center d-flex align-items-center"}
            style={{ textAlign: 'center', flex: '1 0 25%', marginBottom: '20px', padding: '16px' }}>
            <a href={image.link} target="_blanc">
              <img key={index} src={image.image} alt="logo" style={{
                maxWidth: '160px',
              }}
              />
            </a>
          </div>
        ))}
        {/* </Carousel> */}
      </Row>
    </>
  );
}

LogoCarouselSection.propTypes = {
  children: PropTypes.element.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.number,
};

LogoCarouselSection.defaultProps = {
  width: 1,
};

export default function LogoCarousel({ title, description, images }) {
  return (
        <Row className="justify-content-center my-5">
          <Col xs={12} className="text-center">
            <LogoCarouselSection images={images}>
              <TitledText title={title} text={description} titleLevel={3} />
            </LogoCarouselSection>
          </Col>
          <div
            className="d-flex align-items-center justify-content-center nayla-logo my-5">
            <a href="https://naylalabs.com/index.html"  target="_blanc">
            <img className="nayla-logo-img" src={naylalabs} alt={'Naylalabs'}  />
            </a>
          </div>
        </Row>
  );
}

LogoCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LogoCarousel.defaultProps = {
  description: null,
};
