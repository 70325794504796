import React from 'react';
import LogoCarousel from './LogoCarousel';
import { useTranslation } from 'react-i18next';
import columbiaIstanbul from '../media/logos/columbiaGC_logo.svg';
import dot from '../media/logos/dotLe_logo.svg';
import icdf from '../media/logos/ICDF_logo.svg';
import bluechip from '../media/logos/blueChip_logo.svg';
import centerForSustainableDevelopment from '../media/logos/centerfSD_logo.svg';
import aub from '../media/logos/AUB_logo.png';
import biRiZ from '../media/logos/biRiZ_logo.svg';
import istanbulUniversity from '../media/logos/istanbulUni_logo.svg';
import sgyd from '../media/logos/sgyd-logo.png';
import tog from '../media/logos/tog-logo.png';
import gju from '../media/gju.png';
import columbia from '../media/columbia-global-centers-amman.png';
import anadolu from '../media/logos/anadoluUniversity.png';
import gmdp from '../media/logos/gmdp.svg';
import unfpa from '../media/logos/unfpa.png'

const PartnersCarousel = () => {
  const { t } = useTranslation();
  const partnerLogos = [

    {
      image: centerForSustainableDevelopment,
      link: 'https://csd.columbia.edu',
    },
    {
      image: columbiaIstanbul,
      link: 'https://globalcenters.columbia.edu/istanbul',
    },
    {
      image: columbia,
      link: 'https://globalcenters.columbia.edu/amman',
    },
    {
      image: dot,
      link: 'https://lebanon.dotrust.org',
    },
    {
      image: istanbulUniversity,
      link: 'https://www.istanbul.edu.tr/en/_',
    },
    {
      image: anadolu,
      link: 'https://www.anadolu.edu.tr/en/_',
    },
    {
      image: aub,
      link: 'https://www.aub.edu.lb',
    },
    {
      image: gju,
      link: 'http://gju.edu.jo/',
    },
    {
      image: sgyd,
      link: 'http://sagliktagenc.org/en/home/',
    },
    {
      image: gmdp,
      link: 'https://www.gencmulteciler.org/tag/gmdp/',
    },
    {
      image: biRiZ,
      link: 'https://www.birizdernegi.org',
    },
    {
      image: tog,
      link: 'https://www.tog.org.tr/en/',
    },
    {
      image: icdf,
      link: 'https://www.icdf.org.tw/',
    },
    {
      image: bluechip,
      link: 'https://www.bluechipfoundation.org',
    },
    {
      image: unfpa,
      link: 'https://www.unfpa.org/',
    }

  ];
  return (
    <LogoCarousel
      title={t('about.partners.title')}
      description={t('about.partners.description')}
      images={partnerLogos}
    />
  );
};
export default PartnersCarousel;
