import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import TitledText from '../TitledText';
import Team from './Team';
import Quotes from '../Home/Quotes';
import AppSection from '../AppSection';
import teamPhoto from '../../media/teamPhoto.png';
import PartnersCarousel from '../PartnersCarousel';
import screen from '../../media/newsscreen.svg';

function About() {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <img className="w-100" src={teamPhoto} alt="REACH team together" />
      </Row>
      <Row className="row justify-content-center my-5">
        <Col xs={10} sm={8} lg={6} xl={6} className="text-center">
          <TitledText
            title={t('about.title')}
            textLinked
            titleLevel={1}
            isMainDescription={true}
          />
        </Col>
      </Row>

      <Team />

      <Quotes />

      <PartnersCarousel />

      <AppSection
        image={screen}
        classNameimage="newsexplore"
        title={t('about.explore.title')}
        text={t('about.explore.description')}
        titleLevel={3}
        placeholder={t('about.explore.placeholder')}
        btnh={t('about.explore.button')}
      />
    </>
  );
}

export default About;
