import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import i18n from '../../i18n';
import logo from '../../media/logos/logo-footer.png';
import facebook from '../../media/icons/facebook.svg';
import instagram from '../../media/icons/instagram.svg';

function Footer() {
  const { t } = useTranslation();
  const selectLanguage = (language) => {
    if (language === 'ar') {
      i18n.changeLanguage('ar');
      document.documentElement.style.setProperty('--direction', 'rtl');
      document.documentElement.style.setProperty('--align', 'right');
    } else {
      i18n.changeLanguage(language);
      document.documentElement.style.setProperty('--direction', 'ltr');
      document.documentElement.style.setProperty('--align', 'left');
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Row id="footer" className="footer p-5 ">
        <Col
          xs={12}
          md={6}
          lg={12}
          className="pb-4 d-flex justify-content-center align-items-center footer-border"
        >
          <NavLink exact to="/">
            <img className="logo" src={logo} alt="REACH logo" />
          </NavLink>
          <DropdownButton
            id="dropdown-basic-button"
            title={t('nav.footer.language')}
            lang={i18n.language}
          >
            <Dropdown.Item onClick={() => selectLanguage('ar')}>
              العربية
            </Dropdown.Item>
            <Dropdown.Item onClick={() => selectLanguage('tr')}>
              Türkçe
            </Dropdown.Item>
            <Dropdown.Item onClick={() => selectLanguage('en')}>
              English
            </Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col className="footer-link-wrapper p-0">
          <p>
            <span className="p-2">
              <NavLink exact to="/terms-and-conditions">
                {t('nav.footer.terms')}
              </NavLink>
            </span>
            <span className="p-2">
              <NavLink exact to="/privacy-policy">
                {t('nav.footer.policy')}
              </NavLink>
            </span>
            <a className="social-media" href="https://www.facebook.com/reach4healthapp/">
               <img className="social-media-item" src={facebook} alt="Facebook Logo" />
            </a>
            <a className="social-media" href="https://www.instagram.com/reach4healthapp/?utm_medium=copy_link">
              <img className="social-media-item" src={instagram} alt="Instagram Logo" />
            </a>
          </p>
          <p>
          <span className="p-2">
              <NavLink exact to="/health-providers">
                {t('nav.footer.healthProviders')}
              </NavLink>
            </span>
            <span className="p-2">
              <NavLink exact to="/faq">
                {t('nav.footer.faq')}
              </NavLink>
            </span>
            <span className="p-2">
              <NavLink exact to="/contact">
                {t('nav.footer.contact')}
              </NavLink>
            </span>
          </p>
        </Col>
      </Row>
    </>
  );
}

export default Footer;
