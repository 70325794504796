import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import logoSample1 from '../../media/article.svg';
import '../../style/newspage.css';
import TitledText from '../TitledText';
// import { resetNextUuid } from 'react-accessible-accordion';
// import { useParams, useRouteMatch } from 'react-router-dom';
function Newspage() {
  const url =
    'https://public-api.wordpress.com/wp/v2/sites/reach4healthapp.wordpress.com/posts';
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const params = useParams();
  // eslint-disable-next-line object-curly-newline
  const [post, setPost] = useState({});

  useEffect(() => {
    const fetchSingleBlogPost = async () => {
      const data = await fetch(`${url}/${params.id}`);
      const postData = await data.json();
      const postFinal = {
        id: postData.id,
        title: postData.title.rendered.replace(/&nbsp;/g, ' '),
        excerpt: postData.excerpt.rendered,
        content: postData.content.rendered,
        media: postData.jetpack_featured_media_url,
        category: postData.tags.find((tag) => tag === 3867)
          ? 'Article'
          : 'Event',
        author: 'Editor',
        place: 'REACH',
      };
      setPost(postFinal);
    };
    fetchSingleBlogPost();
  }, [params]);

  return (
    <>
      <Row>
        <Col className="post-header" />
      </Row>
      <Row className="news-post d-flex justify-content-center">
        <Col xs={10} className="depth-shadow">
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="p-5 text-center">
              <TitledText title={post.title} titleLevel={2} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="p-3">
              <p
                data-testid="paragraphfirst"
                className="newsParegraph text-center"
                dangerouslySetInnerHTML={{ __html: post.content }}
              >
                {/* {result.firstPart} */}
              </p>
              {post.link && <a href={post.link}>{post.link}</a>}
              {/* <p
                data-testid="paragraphsecond"
                className="newsParegraph mb-5"
                dangerouslySetInnerHTML={{ __html: post.secondPart }}
              > */}
              {/* {result.secondPart} */}
              {/* </p> */}
              {/* {result.video && (
                <iframe
                  width="100%"
                  height="400"
                  className="newsParegraph"
                  src={result.video}
                  title={result.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )} */}
              {/* <img src={result.cover} alt="article" width="100%" />
              <p className="newsParegraph mt-3">{result.thirdPart}</p>
              {result.photos &&
                (result.photos.length > 1
                  ? result.photos.map((photo) => (
                      <img src={photo} alt="" className="w-100 col-md-6 p-2" />
                    ))
                  : result.photos.map((photo) => (
                      <img src={photo} alt="" width="100%" />
                    )))} */}
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }} className="mb-5">
              <p className="auttor">{post.author} </p>
              <p className="place">{post.place}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }} className="mb-5 bg-gray">
              <p className="social-text py-3">{t('news.share')} </p>
              <div className="d-flex justify-content-center py-3">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialLink"
                >
                  <FontAwesomeIcon
                    icon={['fab', 'facebook-f']}
                    transform="shrink-6"
                    mask={['fas', 'circle']}
                    style={{ color: '#4267B2' }}
                    className="socialIcon"
                  />
                  <span className="socialName"> Facebook</span>
                </a>
                <a
                  href="https://www.twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialLink"
                >
                  <FontAwesomeIcon
                    icon={['fab', 'twitter']}
                    transform="shrink-6"
                    mask={['fas', 'circle']}
                    style={{ color: '#55acee' }}
                    className="socialIcon"
                  />
                  <span className="socialName">Twitter</span>
                </a>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialLink"
                >
                  <FontAwesomeIcon
                    icon={['fab', 'linkedin-in']}
                    transform="shrink-6"
                    mask={['fas', 'circle']}
                    style={{ color: '#0e76ab' }}
                    className="socialIcon"
                  />
                  <span className="socialName"> LinkedIn</span>
                </a>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialLink"
                >
                  <FontAwesomeIcon
                    icon={['fab', 'whatsapp']}
                    transform="shrink-6"
                    mask={['fas', 'circle']}
                    style={{ color: '#25d366' }}
                    className="socialIcon"
                  />
                  <span className="socialName"> Whatsapp</span>
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          xs={10}
          md={{ span: 4, offset: 4 }}
          className="justify-content-center pt-5"
        >
          <TitledText
            title={t('news.comment-title')}
            text={t('news.comment-description')}
            titleLevel={3}
            align="center"
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12} md={{ span: 6, offset: 3 }}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} className="text-center justify-content-center">
                <Form.Control
                  as="textarea"
                  rows="5"
                  required
                  size="lg"
                  placeholder={t('news.comment-placeholder')}
                />

                <Button type="submit" className="new-btn my-4 mx-md-2">
                  {t('news.submit')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default Newspage;
