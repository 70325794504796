import React from 'react';
import {
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Subtopic from './Subtopic';
import TitledText from '../TitledText';
import cat1 from '../../media/clipboard.svg';
import cat2 from '../../media/stethoscope.svg';
import cat3 from '../../media/apple.svg';
import cat4 from '../../media/head.svg';
import cat5 from '../../media/social.svg';
import cat6 from '../../media/trees.svg';
import { Context } from '../../ContextProvider';

const categoryImages = [cat1, cat3, cat2, cat4, cat5, cat6];

function Topic() {
  const { topicId } = useParams();
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const allTopics = t('topics.categories.topics', { returnObjects: true });

  const foundTopic = allTopics.find(
    topic => topic.key === topicId
  );
  const topicIndex = allTopics.indexOf(foundTopic);
  if (!foundTopic) {
    return <Redirect to='/404' />;
  }
  return (
    <Switch>
      <Route exact path={path}>
        <TopicPage topicIndex={topicIndex} foundTopic={foundTopic} allTopics={allTopics} />
      </Route>
      <Route path={`${path}/:subtopicId`}>
        <Subtopic />
      </Route>
    </Switch>
  );
}

function TopicPage({ topicIndex, foundTopic, allTopics }) {
  const { t } = useTranslation();
  return (
    <>
      <Context.Consumer>
        {(context) => (
          <>
            <Row className='post-header'>
              <Col className={'post-header topic-header'} />
            </Row>
            <Row className='topic-post d-flex justify-content-center'>
              <Col xs={10} className='depth-shadow topic-depth-shadow'>
                <div className='mt-5 topic-image mx-auto '>
                  <Col className='text-center topic-center'>
                    {categoryImages[topicIndex]   ?
                      <img className='image-item' src={categoryImages[topicIndex]} alt='Provider Service' /> :
                      <img className='image-item' src={categoryImages[0]} alt='Provider Service' />
                    }
                  </Col>
                </div>
                <Row className={'mt-5'}>
                  <Col className='text-center topic-center'>
                    <p className='topic-badge'>
                      {`${t(`topics.categories.topics.${topicIndex}.subTopics`, { returnObjects: true }).length} `}
                      {`${t(`subTopic`, { returnObjects: true })} `}
                    </p>
                  </Col>
                </Row>
                <Row className={'justify-content-center'}>
                  <Col className={'text-center col-lg-6 col-10'}>
                    <TitledText
                      title={t(`topics.categories.topics.${topicIndex}.title`)}
                      text={t(`topics.categories.topics.${topicIndex}.description`)}
                      titleLevel={1}
                    />
                  </Col>
                </Row>
                {allTopics[topicIndex].subTopics
                  .filter(item => {
                    if(foundTopic.id !== 'HEALTHCARE_SYSTEM') return true;
                    return item.country === context.global.country;
                  })
                  .map(
                  (subTopic, stIndex) => (
                    <Link key={stIndex} className='topic-link-item' to={`./${foundTopic.key}/${subTopic.id}`}>
                      <Row className='subtopic d-flex align-items-center pt-3 col-md-8 mx-auto'>
                        <Col xs={10}>
                          <p className={'topic-category-link'}>
                            {subTopic.title}
                          </p>
                        </Col>
                        <Col xs={2}>
                          <FontAwesomeIcon className={'icon-design'} icon={['fas', 'arrow-right']} />
                        </Col>
                      </Row>
                    </Link>
                  )
                )}
              </Col>
            </Row>
          </>
        )}
      </Context.Consumer>
    </>
  );
}

export default Topic;
