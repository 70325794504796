import React from 'react';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TopicFAQ from './TopicFAQ';
import TitledText from '../TitledText';
import FaqForm from './FaqForm';

import './faqstyle.css';

function MainFAQSCat() {
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    function getCat() {
      const data = t('topics.categories.topics', { returnObjects: true }).filter((cat) =>
        cat.name.toLowerCase().includes(`${searchTerm}`.toLowerCase())
      );

      if (data) {
        setCategories(data);
      } else {
        setCategories([]);
      }
    }
    getCat();
  }, [searchTerm, t]);

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <div>
            <FaqForm setSearchTerm={setSearchTerm} />
            <h1 className="text-center py-5"> Categories</h1>
          </div>
          <Row>
            {categories.map((cat, index) => (
              <Col xs={4}>
                <Link to={`${url}/topic-${index}`} className="links">
                  <div key={index} className="categories text-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={`../../static/media/${cat.img}`}
                        alt={cat.title}
                      />
                    </div>
                    <TitledText
                      title={t(`${cat.name}`)}
                      text={t(`${cat.description}`)}
                      titleLevel={4}
                    />
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Route>
        <Route path={`${path}/:topicId`}>
          <TopicFAQ />
        </Route>
      </Switch>
    </>
  );
}

export default MainFAQSCat;
