import React from 'react';
import { Form, Row } from 'react-bootstrap';
import '../style/search.css';

export default function Search({changeKeyword}) {

  const handleChange = event => {
    changeKeyword(event.target.value);
  };

  return (
    <Row className='search__container justify-content-center'>
      <Form className={'form-padding col-md-7'}>
        <input
          type='text'
          className='search-box'
          onChange={handleChange}
        />
      </Form>
    </Row>
  );
}
