import React, { Component } from 'react';
import './accordion.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AccordionItem extends Component {
  state = {
    opened: false,
  };

  render() {
    const {
      props: { title, content },
      state: { opened },
    } = this;

    return (
      <div
        className={`accordion-item, ${opened && 'accordion-item--opened'}`}
        onClick={() => {
          this.setState({ opened: !opened });
        }}
      >
        <div className="accordion-item__line py-4 bg-transparent">
          <p className="accordion-item__title mb-0">{title}</p>
          <div className="accordion-item__icon">
            <FontAwesomeIcon className={'icon-design'} icon={['fas', 'arrow-right']} />
          </div>
        </div>
        <div className="accordion-item__inner">
          <div className="accordion-item__content pb-0">
            <p className="accordion-item__paragraph">{content}</p>
          </div>
        </div>
      </div>
    );
  }
}

const Accordion = ({filteredFaq}) => {
  return (
    <div className="wrapper">
      <ul className="accordion-list">
        {filteredFaq.map((data, key) => {
          return (
            <li className="accordion-list__item" key={key}>
              <AccordionItem {...data} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default Accordion;
