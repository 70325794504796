import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../style/topic.css';
import { Col, Form, Row } from 'react-bootstrap';
import DetailPageBackground from '../media/detail-page-background.png';
import DetailPhone from '../media/detail-phone.png';
import DetailMail from '../media/detail-mail.png';
import StarRatings from 'react-star-ratings';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase';
import getDistanceBetweenPoints from 'get-distance-between-points';
import { Redirect } from 'react-router';

function HospitalDetail() {
  const { t } = useTranslation();
  const { hospitalId } = useParams();

  const hospital = t('providers', { returnObjects: true}).find(
    hospital => hospital.id === hospitalId
  );

  const [rating, setRating] = useState(2);
  const [review, setReview] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    db.collection('providerReviews')
      .add({
        providerName: hospital.name,
        rating: rating,
        review: review,
        web: true
      })
      .then(() => {
        alert('işlem başarılı');
      })
      .catch(() => {
        alert('işlem başarısız');
      });
    setReview('');
  };

  /** GELOCATION **/
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(false);
  /** E:GELOCATION **/

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus(false);
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(true);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      }, () => {
        setStatus(false);
      });
    }
  }

  useEffect(()=> {
    getLocation()
  }, []);

  function getDistance(provider){
    if(!lat || !lng || !status) return '+99KM';
    const distance = getDistanceBetweenPoints.getDistanceBetweenPoints(
      lat,lng,provider.lat,provider.lng
    ) * 0.001;
    return Math.floor(distance) + ' KM'
  }

  function changeRating(newRating) {
    setRating(newRating);
  }

  return (
    <>
      {hospital == null ?
        <Redirect to="/health-providers" /> :
        <div>
          <Row className='subtopic-header detail-header'>
            <img src={DetailPageBackground} className='w-100' alt='Grapes on plates' />
          </Row>
          <div fluid className='d-flex justify-content-center px-0 row pb-5 subtopic-body-style'>
            <Col xs={11} lg={10} className='subtopic-body detail-body mx-3 mb-5 px-0 mt-1 pb-5 '>
              <div className='container'>
                <div className='header-detail'>
                  <p className='topic-badge detail-badge m-auto'>{t('hospitalDetailTitle')}</p>
                  <h2 className='detail-title my-4'>{t(hospital.name)}</h2>
                  <div className='detail-info m-auto'>
                    <span className='detail-info-item'>{t(hospital.type)}</span>
                    <span className='detail-info-item'>{getDistance(hospital)}</span>
                    <span className='detail-info-item'>Open 24/7</span>
                  </div>
                  <div className='detail-buttons m-auto'>
                    <a className='detail-button-item' href={'tel: ' + hospital.tel}>
                      <img src={DetailPhone} alt='Phone Icon' className='detail-icon' />
                      <span>{hospital.tel}</span>
                    </a>
                    <a className='detail-button-item' href='mailto: info-mail@gmail.com'>
                      <img src={DetailMail} alt='detail-mail' className='detail-icon' />
                      <span>info-mail@gmail.com</span>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </div>
          <div className='hospital-review'>
            <div className='container'>
              <div className='address col-md-10 col-sm-12 mx-auto'>
                <p className='address-item'>{t(hospital.address)}</p>
              </div>
              <div className='review-wrapper col-md-10 col-12 mx-auto'>
                <div className='review'>
                  <div className='text mb-4'>
                    <h2 className='text-title'>{t('hospitalDetailReview.title')}</h2>
                    <p className='text-description'>{t('hospitalDetailReview.description')}</p>
                  </div>
                  <div className='stars'>
                    <p className='review-question'>{t('hospitalDetailReview.questions')}</p>
                    <StarRatings
                      rating={rating}
                      numberOfStars={5}
                      name='rating'
                      changeRating={changeRating}
                      starRatedColor={'#173F70'}
                      starEmptyColor={'#D2D2D2'}
                      starHoverColor={'#173F70'}
                      starDimension={'40px'}
                      starSpacing={'10px'}
                    />
                  </div>
                  <Form
                    onSubmit={handleSubmit}
                  >
                    <Row className='pt-2 pt-md-4 px-3'>
                      <Col xs={12} md={12} className='p-0'>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                          <Form.Control
                            placeholder={t('hospitalDetailReview.placeholder')}
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                            as='textarea'
                            rows={7} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className='text-center'>
                        <button
                          type='submit'
                          size='lg'
                          className='btn p-2 my-4 submit-button-hover'
                        >
                          {t('hospitalDetailReview.buttonText')}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default HospitalDetail;
;

