import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Carousel from '@brainhubeu/react-carousel';
import TitledText from '../TitledText';
import arrow from '../../media/arrow-right.png';
import '../../style/carousel.css';

function Team() {
  const { t, i18n } = useTranslation();
  const [countrySelect, setCountry] = useState('View All');
  const carouselSettings = {
    centered: true,
    infinite: true,
    //arrows:true,
    arrowLeft: (
      <img alt='carousel arrow' className='slider-arrow-right' src={arrow} />
    ),
    arrowRight: (
      <img alt='carousel arrow' className='slider-arrow-left' src={arrow} />
    ),
    addArrowClickHandler: true,
    clickToChange: true,
    slidesPerPage: 1,
    dots: false,
    rtl: i18n.language === 'ar',
    indicators: true
  };
  let memberIdxInJSON = [];

/*
  countrySelect.key !== 'View All'
    ? t('about.team.members', { returnObjects: true }).forEach(
      (member, index) =>
        member.country === countrySelect.key && memberIdxInJSON.push(index)
    )
    : t('about.team.members', { returnObjects: true }).forEach((member, index) => memberIdxInJSON.push(index));
*/

  if (countrySelect !== 'View All') {
    if (countrySelect.key !== 'View All') {
      t('about.team.members', { returnObjects: true }).forEach(
        (member, index) =>
          member.key === countrySelect.key && memberIdxInJSON.push(index)
      );
    } else {
      t('about.team.members', { returnObjects: true }).forEach((member, index) => memberIdxInJSON.push(index));
    }
  } else {
    t('about.team.members', { returnObjects: true }).forEach((member, index) => memberIdxInJSON.push(index));
  }

  const rows = splitInChunks(5, memberIdxInJSON);
  const pages = splitInChunks(3, rows);

  return (
    <Row className='justify-content-center team-section'>
      <Col xs={12} className='p-0'>
        <Col className='text-center mx-auto py-5 team-card '>
          <TitledText title={t('about.team.title')} titleLevel={3} />
          <Row className='justify-content-center'>
            <ul className='list-inline'>
              {t('about.team.countries', { returnObjects: true }).map((country, countryIndex) => (
                <li
                  key={countryIndex}
                  onClick={() => setCountry(country)}
                  className={`country-button text-center list-inline-item mx-2 my-3 py-3 px-4 ${
                    country === countrySelect.key ? 'country-button-active' : ''
                  }`}
                >
                  {t(`about.team.countries.${countryIndex}.value`, { returnObjects: true })}
                </li>
              ))}
            </ul>
          </Row>
          <Carousel {...carouselSettings} className='md-team-carousel'>
            {pages.map((pageRows, pageIdx) => (
              <Row className='justify-content-center' key={pageIdx}>
                {pageRows.map((row, rowIdx) => (
                  <Col md={4} key={pageIdx + rowIdx}>
                    {row.map((item, itemIdx) => (
                      <TitledText
                        key={pageIdx + rowIdx + itemIdx}
                        title={t(`about.team.members.${item}.name`)}
                        text={t(`about.team.members.${item}.organization`)}
                        titleLevel={4}
                        align='center'
                        className='my-2 py-2 team-member'
                      />
                    ))}
                  </Col>
                ))}
              </Row>
            ))}
          </Carousel>
        </Col>
      </Col>
    </Row>
  );
}

function splitInChunks(chunkSize, array) {
  let chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export default Team;
