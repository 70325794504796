import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import TopicLinks from './TopicLinks';
import TopicServices from './TopicServices';
import TopicFAQs from './TopicFAQs';
import TitledText from '../TitledText';
import '../../style/topic.css';
import BackgroundImage from '../../media/subtopic-background.png';

function Subtopic() {
  const { topicId, subtopicId } = useParams();
  const { t } = useTranslation();
  const topics = t('topics.categories.topics', { returnObjects: true });
  const foundTopic = topics.find(item => item.key === topicId);
  const topicIndex = topics.indexOf(foundTopic);
  const foundSubtopic = foundTopic.subTopics.find(item => item.id === subtopicId);
  const subtopicIndex = foundTopic.subTopics.indexOf(foundSubtopic);

  if (!foundTopic) return <Redirect to='/404' />;
  if (!foundSubtopic) return <Redirect to='/topics' />;

  return (
    <>
      <Row className='subtopic-header'>
        <img src={BackgroundImage} className='w-100' alt='Grapes on plates' />
      </Row>
      <div fluid='true' className='d-flex justify-content-center px-0 row pb-5 subtopic-body-style'>
        <Col xs={11} lg={10} className='subtopic-body mx-3 mb-5 px-0 mt-1 pb-5 '>
          <SubTopicCard
            foundTopic={foundTopic}
            foundSubtopic={foundSubtopic}
            topicIndex={topicIndex}
            subtopicIndex={subtopicIndex}
          />
        </Col>
      </div>
    </>
  );
}

function SubTopicCard({ foundTopic, foundSubtopic, topicIndex, subtopicIndex }) {

  const { t } = useTranslation();
  const [display, setDisplay] = useState('Services');
  const [providers, setProviders] = useState([]);
  const [faqs, setFAQs] = useState([]);

  useEffect(() => {
    setProviders(t('providers', { returnObjects: true }).filter(
      item => item.services.includes(foundTopic.key)
    ));
    setFAQs(t('faqs', { returnObjects: true }).filter(
      item => item.services.includes(foundTopic.key)
    ));
  }, [foundTopic, t]);

  useEffect(() => {
    setFAQs(t('faqs', { returnObjects: true }).filter(
      item => item.services.includes(foundSubtopic.key)
    ));
  }, [foundSubtopic, t]);


  return (
    <>
        <Col className={'p-0'}>
          <Col className='justify-content-center p-0'>
            <div
              className='subtopic-header-style mb-5 pt-5 pb-3 d-flex flex-column justify-content-center align-items-center'>
              <p className='text-center topic-badge mb-2'>{t(`topics.categories.topics.${topicIndex}.title`)}</p>
              <TitledText
                title={t(
                  `topics.categories.topics.${topicIndex}.subTopics.${subtopicIndex}.title`
                )} />
            </div>
            <TitledText
              title={''}
              titleLevel={1}
              className={'col-12 col-md-8 m-auto text-left'}
              text={foundSubtopic.contents.join('<p></p>')} />
          </Col>

          <Row className='my-sm-5 col-12 col-md-8 m-auto topic-links p-2'>
            {t('subtitles', { returnObjects: true }).map((subtitle, index) => (
              <Col
                key={index}
                className={`${
                  display === subtitle.display ? 'active-topic-link topic-link' : ''
                } text-center topic-link`}
                onClick={() => setDisplay(subtitle.display)}
              >
                {subtitle.title}
              </Col>
            ))}
          </Row>

          {display === 'Services' &&
            <Col className='px-0 col-12'>
              <TopicServices providers={providers} foundTopic={foundTopic} />
            </Col>
          }
          {display === 'Links' &&
            <Col className='px-0 col-12 col-md-8 m-auto'>
              <TopicLinks resources={foundSubtopic.resources.links} />
            </Col>
          }
          {display === 'FAQs' &&
            <Col className='px-0 col-12 col-md-8 m-auto'>
              <TopicFAQs faqs={faqs} />
            </Col>
          }
        </Col>
    </>
  );
}

export default Subtopic;
